import React from "react";
import { Container, Box, Typography, Divider } from "@mui/material";
import { useLocation } from "react-router-dom";

const ArticlePage = () => {
  const location = useLocation();
  const { article } = location.state;

  return (
    <Container style={{ paddingTop: "150px" }}>
      <Box style={{ border: "1px solid black", padding: "20px" }}>
        <Typography variant="h4" gutterBottom>
          {article.title}
        </Typography>
        {article.content.sections.map((section, index) => (
          <Box key={index} mb={2}>
            <Typography variant="h6" gutterBottom>
              {section.heading}
            </Typography>
            <Typography variant="body1">{section.content}</Typography>
            {index === Math.floor(article.content.sections.length / 2) && (
              <Box
                component="img"
                src={article.content.image}
                alt={article.content.title}
                style={{
                  width: "80%",
                  height: "auto",
                  marginBottom: "20px",
                  marginLeft: "150px",
                  marginTop: "30px",
                }}
              />
            )}
            {index < article.content.sections.length - 1 && (
              <Divider style={{ margin: "20px 0" }} />
            )}
          </Box>
        ))}
      </Box>
    </Container>
  );
};

export default ArticlePage;
