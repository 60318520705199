import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Home from "./Components/Home/Home";
// import About from "./Components/About/About";
import Services from "./Components/Services/Services";
import VendorSelectionCriteria from "./Components/Tools/VendorSelectionCriteria/VendorSelectionCriteria";
import CTFramework from "./Components/Tools/CTFramework/CTFramework";
import Support from "./Components/Support/Support";
import Chart from "./Components/Tools/Chart/Chart";
import Navbar from "./Components/Navbar/Navbar";
import AppliedAIEngineering from "./Components/Services/Applied-AI- Engineering/AppliedAIEngineering";
import AppliedDataScience from "./Components/Services/Applied-Data-Science/AppliedDataScience";
import AILedAssurance from "./Components/Services/AI-Led-Assurance/AILedAssurance";
import AILedQuality from "./Components/Services/AI-Led-Quality/AILedQuality";
import DigitalServices from "./Components/Services/DigitalServices/DigitalServices";
import AIAugumentedTesting from "./Components/Testing/AI-Augumented-Testing/AIAugumentedTesting";
import Footer from "./Components/Footer/Footer";
import CardDetail from "./Components/Home/CardDetail/CardDetail";
import CreateAccount from "./Components/Home/CreateAccount/CreateAccount";
import Blogs from "./Components/NavOptions/Resources/Blogs/Blogs";
import Research from "./Components/NavOptions/Resources/Research/Research";
import Whitepapers from "./Components/NavOptions/Resources/WhitePaper/Whitepapers";
import Contact from "./Components/Footer/FooterOptions/Contact/Contact";
import TestIo from "./Components/Tools/TextIO/TextIo";
import TemplateComponent from "./Components/Services/TemplateComponent/TemplateComponent";
import ScrollToTop from "./Scroll/ScrollToTop";
import About from "./Components/NavOptions/About/About";
import Conferences from "./Components/NavOptions/Conferences/Conferences";
import NewsRoom from "./Components/NavOptions/NewsRoom/NewsRoom";
import Careers from "./Components/NavOptions/Careers/Careers";
import Signin from "./Components/NavOptions/Signin/Signin";
import ArticlePage from "./Components/NavOptions/Resources/Blogs/Article";
import GPUBenchmarks from "./Components/Footer/FooterOptions/Resources/GPUBenchmarks/GPUBenchmarks";
import Documentation from "./Components/Footer/FooterOptions/Resources/Documentation/Documentation";
import Forum from "./Components/Footer/FooterOptions/Resources/Forum/Forum";
import Partners from "./Components/Footer/FooterOptions/Company/Partners/Partners";
import ExpertGuidence from "./Components/Footer/FooterOptions/Company/ExpertGuidence/ExpertGuidence";
import TechnicalSupport from "./Components/Footer/FooterOptions/Support/TechnicalSupport/TechnicalSupport";
import PartnerPortal from "./Components/Footer/FooterOptions/Support/PartnerPortal/PartnerPortal";
import Chintu from "./Components/NavOptions/Compute/Chintu/Chintu";
import ScalarServer from "./Components/NavOptions/Compute/ScalarServer/ScalarServer";
import NVDIA from "./Components/NavOptions/Compute/NVDIA/NVDIA";
import ScheduleCall from "./Components/Home/ScheduleCall/ScheduleCall";
import OptimaAI from "./Components/Tools/OptimaAI/OptimaAI";
import LearnMoreForAI from "./Components/Home/LearnMore/LearnMoreForAI";
import OptimaStackInfo from "./Components/Home/LearnMore/OptimaStackInfo/OptimaStackInfo";
import IntroducingClusters from "./Components/Home/LearnMore/IntroducingClusters/IntroducingClusters";
import AIAndMLPartner from "./Components/Home/AIAndMLPartner/AIAndMLPartner";
import PrivacyPolicy from "./Components/Footer/FooterOptions/PrivacyPolicy/PrivacyPolicy";
import TermsOfService from "./Components/Footer/FooterOptions/TermsOfServices/TermsofServices";
import Development from "./Components/Home/AppliedAIEngineeringOptions/Development/Development";
import OptimaGRC from "./Components/Tools/OptimaGRC/OptimaGRC";
import LaunchInstance from "./Components/Home/LearnMore/LaunchInstance/LaunchInstance";
import ReserveInstance from "./Components/Home/LearnMore/ReservedCloud/ReservedCloud";
import Pricing from "./Components/Home/LearnMore/Pricing/Pricing";
import OptimaLabs from "./Components/NavOptions/OptimaLabs/OptimaLabs";
import OWS from "./Components/NavOptions/OWS/OWS";
import SigninClient from "./Components/Home/CreateAccount/SigninClient";
import AI360 from "./Components/Home/AI360/AI360";
import CyberSecurity from "./Components/Home/CyberSecurity/CyberSecurity";
import ComputeDataCenter from "./Components/Home/ComputeDataCenter/ComputeDataCenter";
// import ScalarServer from "./Components/NavOptions/Compute/ScalarServer/ScalarServer";
// import TestIo from "./Components/Tools/TestIO/TestIo";

import ReactGA from "react-ga";
import CookieBanner from "./CookieBanner/CookieBanner";
import NotFound from "./NotFound";

// Initialize with your Measurement ID
// ReactGA.initialize("G-2SR8VLJV1X");
ReactGA.initialize("G-4BG656T8CJ", { debug: true });

const components = {
  "ai-engineering": AppliedAIEngineering,
  "data-science": AppliedDataScience,
  "ai-quality": AILedQuality,
  "ai-assurance": AILedAssurance,
};

const App = () => {
  useEffect(() => {
    // Track the initial page load
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  console.log("Page is Loading");

  return (
    <BrowserRouter>
      <Layout />
    </BrowserRouter>
  );
};

const Layout = () => {
  const location = useLocation();

  useEffect(() => {
    // Track page views on route changes
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <>
      <CookieBanner />
      <ScrollToTop />
      {location.pathname !== "/create-account" && <Navbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/details/:id" element={<CardDetail />} />
        <Route path="/about" element={<About />} />
        {/* <Route path="/ai-engineering" element={<AppliedAIEngineering />} />
        <Route path="/data-science" element={<AppliedDataScience />} />
        <Route path="/ai-quality" element={<AILedQuality />} />
        <Route path="/ai-assurance" element={<AILedAssurance />} /> */}
        {/* <Route path="/:option" element={<TemplateComponent components={components} />} /> */}
        <Route
          path="/:option/*"
          element={<TemplateComponent components={components} />}
        />
        <Route path="/radius" element={<VendorSelectionCriteria />} />
        <Route path="/digitalservices" element={<DigitalServices />} />
        <Route path="/AIAugumentedTesting" element={<AIAugumentedTesting />} />
        <Route path="/chart" element={<Chart />} />
        <Route path="/axiom" element={<CTFramework />} />
        <Route path="/optimagrc" element={<OptimaGRC />} />
        <Route path="/ai360" element={<AI360 />} />
        <Route path="/cyber-security" element={<CyberSecurity />} />
        <Route path="/compute-data-center" element={<ComputeDataCenter />} />

        <Route path="/support" element={<Support />} />

        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/article" element={<ArticlePage />} />

        <Route path="/leranmoreforAI" element={<LearnMoreForAI />} />
        <Route path="/optimaStackInfo" element={<OptimaStackInfo />} />
        <Route path="/introducingClusters" element={<IntroducingClusters />} />
        <Route path="/aiandmlPartner" element={<AIAndMLPartner />} />

        <Route path="/research" element={<Research />} />
        <Route path="/white-papers" element={<Whitepapers />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/optimaAI" element={<OptimaAI />} />
        <Route path="/testIO" element={<TestIo />} />
        <Route path="/conferences" element={<Conferences />} />
        <Route path="/optimalabs" element={<OptimaLabs />} />
        <Route path="/ows" element={<OWS />} />

        <Route path="/newsroom" element={<NewsRoom />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/sign-up" element={<Signin />} />
        <Route path="/signin" element={<SigninClient />} />
        <Route path="/cloudpricing" element={<Pricing />} />

        <Route path="/benchmarks" element={<GPUBenchmarks />} />
        <Route path="/documentation" element={<Documentation />} />
        <Route path="/forum" element={<Forum />} />
        <Route path="/expertguidance" element={<ExpertGuidence />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/launchaninstance" element={<LaunchInstance />} />
        <Route path="/reservenow" element={<ReserveInstance />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termsOfservices" element={<TermsOfService />} />

        <Route path="/technicalsupport" element={<TechnicalSupport />} />
        <Route path="/partnerportal" element={<PartnerPortal />} />

        {/* <Route path="/scalarserver" element={<ScalarServer />} /> */}
        <Route path="/OPTIMA" element={<NVDIA />} />
        <Route path="/scalarserver" element={<ScalarServer />} />
        <Route path="/chintu" element={<Chintu />} />
        <Route path="/scheduleCall" element={<ScheduleCall />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
