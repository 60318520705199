import React from "react";
import { Grid, Typography, Box } from "@mui/material";

const OptimaAI = () => {
  return (
    <Box
      sx={{
        padding: {
          xs: "190px 20px",
          sm: "150px 40px",
          md: "150px 60px",
          lg: "190px 80px",
        },
      }}
    >
      <Typography
        variant="h2"
        component="h1"
        gutterBottom
        sx={{
          fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem", lg: "3rem" },
        }}
      >
        What we are saying about OptimaAI?
      </Typography>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6} sx={{ position: "relative" }}>
          <img
            src="https://img.freepik.com/premium-photo/ai-intelligence-artificial-robot_947865-56836.jpg?uid=R157426818&ga=GA1.1.425741652.1718973890&semt=ais_hybrid"
            alt="image"
            style={{ width: "100%", height: "auto" }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h6"
            component="p"
            gutterBottom
            sx={{
              fontSize: {
                xs: "1rem",
                sm: "1.25rem",
                md: "1.5rem",
                lg: "1.75rem",
              },
            }}
          >
            <h3>
              Introducing Optima AI:{" "}
              <span style={{ fontWeight: "bold" }}>
                Platform that handles job description creation, candidate resume
                parsing
              </span>
            </h3>
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={{
              fontStyle: "italic",
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1.125rem",
                lg: "1.25rem",
              },
            }}
          >
            "Optima AI aims to streamline and enhance the recruitment process
            for companies by providing a comprehensive platform that handles job
            description creation, candidate resume parsing, and job matching
            functionalities."
          </Typography>
        </Grid>
      </Grid>

      <Box mt={6}>
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          sx={{
            fontSize: {
              xs: "1.25rem",
              sm: "1.5rem",
              md: "1.75rem",
              lg: "2rem",
            },
          }}
        >
          Purpose
        </Typography>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography
              variant="body1"
              component="p"
              gutterBottom
              sx={{
                fontSize: {
                  xs: "0.875rem",
                  sm: "1rem",
                  md: "1.125rem",
                  lg: "1.25rem",
                },
              }}
            >
              This project aims to streamline and enhance the recruitment
              process for companies by providing a comprehensive platform that
              handles job description creation, candidate resume parsing, and
              job matching functionalities.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img
              src="https://img.freepik.com/premium-photo/thoughtful-businessman-programmer-casual-wear-typing-laptop-office-workplace-concept-tech-work-business-education-internet-surfing-information-technology-ai-hologram_269648-14574.jpg?uid=R157426818&ga=GA1.1.425741652.1718973890&semt=ais_hybrid"
              alt="Purpose Image"
              style={{ width: "100%", height: "auto" }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box mt={6}>
        {/* <Typography
          variant="h4"
          component="h2"
          gutterBottom
          sx={{
            fontSize: {
              xs: "1.25rem",
              sm: "1.5rem",
              md: "1.75rem",
              lg: "2rem",
            },
          }}
        >
          Key Functionalities:
        </Typography> */}

        <Box mt={4}>
          <Typography
            variant="h6"
            component="h3"
            gutterBottom
            sx={{
              fontSize: {
                xs: "1rem",
                sm: "1.25rem",
                md: "1.5rem",
                lg: "1.75rem",
              },
            }}
          >
            Company Management
          </Typography>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <img
                src="https://img.freepik.com/free-photo/young-creative-people-with-laptop-notepad-working-modern-office-cool-guys-discussing-new-project-together-while-spending-time-work_574295-5787.jpg?uid=R157426818&ga=GA1.1.425741652.1718973890&semt=ais_hybrid"
                alt="Company Management Image"
                style={{ width: "100%", height: "auto" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant="body1"
                component="p"
                gutterBottom
                sx={{
                  fontSize: {
                    xs: "0.875rem",
                    sm: "1rem",
                    md: "1.125rem",
                    lg: "1.25rem",
                  },
                }}
              >
                <Typography varent="h6" component="p">
                  <strong>Company Creation:</strong> Platform Owner (techOptima)
                  can create and manage company profiles, including contact
                  details, industry information, company size, and headquarters
                  location.
                </Typography>
                <Typography>
                  <strong>User Management:</strong> Companies (Company Owner)
                  can add users with different roles such as company admin, and
                  company user.
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box mt={4}>
          <Typography
            variant="h6"
            component="h3"
            gutterBottom
            sx={{
              fontSize: {
                xs: "1rem",
                sm: "1.25rem",
                md: "1.5rem",
                lg: "1.75rem",
              },
            }}
          >
            Job Description Management
          </Typography>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography
                variant="body1"
                component="p"
                gutterBottom
                sx={{
                  fontSize: {
                    xs: "0.875rem",
                    sm: "1rem",
                    md: "1.125rem",
                    lg: "1.25rem",
                  },
                }}
              >
                <ul>
                  <li>
                    <strong>Create Job Descriptions:</strong> Company users can
                    create detailed job descriptions, specifying role
                    requirements, responsibilities, and qualifications.
                  </li>
                  <li>
                    <strong>Job Listings:</strong> Job descriptions are stored
                    and associated with the respective company. Company users
                    can view and manage all job listings created by their
                    company.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <img
                src="https://img.freepik.com/free-photo/job-description-employment-hiring-recruitment-concept_53876-133728.jpg"
                alt="Job Description Management Image"
                style={{ width: "100%", height: "auto" }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box mt={4}>
          <Typography
            variant="h6"
            component="h3"
            gutterBottom
            sx={{
              fontSize: {
                xs: "1rem",
                sm: "1.25rem",
                md: "1.5rem",
                lg: "1.75rem",
              },
            }}
          >
            Resume Parsing and Job Matching
          </Typography>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <img
                src="https://img.freepik.com/free-photo/resume-application-employment-form-concept_53876-132184.jpg?uid=R157426818&ga=GA1.1.425741652.1718973890&semt=ais_hybrid"
                alt="Resume Parsing Image"
                style={{ width: "100%", height: "auto" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant="body1"
                component="p"
                gutterBottom
                sx={{
                  fontSize: {
                    xs: "0.875rem",
                    sm: "1rem",
                    md: "1.125rem",
                    lg: "1.25rem",
                  },
                }}
              >
                <ul>
                  <li>
                    <strong>Resume Upload:</strong> A user can upload their
                    resumes to the platform. The system uses AI to parse the
                    resume content, extracting key information such as name,
                    email, phone number, education, and skills.
                  </li>
                  <li>
                    <strong>Job Matching:</strong> The platform compares the
                    parsed resume details with job descriptions to determine the
                    best match. A match percentage is calculated to indicate how
                    well the candidate fits the job requirements.
                  </li>
                  <li>
                    <strong>Parsed Data Display:</strong> Parsed resume data and
                    match recommendations are displayed, providing company users
                    with quick insights into the candidate's suitability for the
                    job.
                  </li>
                </ul>
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box mt={4}>
          <Typography
            variant="h6"
            component="h3"
            gutterBottom
            sx={{
              fontSize: {
                xs: "1rem",
                sm: "1.25rem",
                md: "1.5rem",
                lg: "1.75rem",
              },
            }}
          >
            Email Notifications
          </Typography>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography
                variant="body1"
                component="p"
                gutterBottom
                sx={{
                  fontSize: {
                    xs: "0.875rem",
                    sm: "1rem",
                    md: "1.125rem",
                    lg: "1.25rem",
                  },
                }}
              >
                <ul>
                  <li>
                    <strong>Welcome Emails:</strong> Upon user creation, the
                    platform sends a welcome email to the new user with their
                    login credentials.
                  </li>
                  <li>
                    <strong>Candidate Notifications:</strong> Company users can
                    send email notifications to candidates directly from the
                    platform, facilitating communication.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <img
                src="https://img.freepik.com/premium-photo/close-up-laptop-desk-office_1048944-28522546.jpg?uid=R157426818&ga=GA1.1.425741652.1718973890&semt=ais_hybrid"
                alt="Email Notifications Image"
                style={{ width: "100%", height: "auto" }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box mt={4}>
          <Typography
            variant="h6"
            component="h3"
            gutterBottom
            sx={{
              fontSize: {
                xs: "1rem",
                sm: "1.25rem",
                md: "1.5rem",
                lg: "1.75rem",
              },
            }}
          >
            Use Cases
          </Typography>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <img
                src="https://img.freepik.com/premium-photo/writing-paperwork-people-startup-meeting-brainstorming-review-collaboration-notes-support-designer-with-teamwork-business-planning-conversation-project-report_590464-371707.jpg?uid=R157426818&ga=GA1.1.425741652.1718973890&semt=ais_hybrid"
                alt="Use Cases Image"
                style={{ width: "100%", height: "auto" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant="body1"
                component="p"
                gutterBottom
                sx={{
                  fontSize: {
                    xs: "0.875rem",
                    sm: "1rem",
                    md: "1.125rem",
                    lg: "1.25rem",
                  },
                }}
              >
                <ul>
                  <li>
                    <strong>Company Owner:</strong> Can create and manage
                    multiple company user profiles, add users, and oversee the
                    recruitment process.
                  </li>
                  <li>
                    <strong>Company Admins:</strong> Can create job
                    descriptions, upload resumes, view parsed data and job match
                    recommendations to identify suitable candidates.
                  </li>
                  <li>
                    <strong>Candidates:</strong> Can upload their resumes, which
                    are then parsed and matched against available job
                    descriptions to help them find suitable positions.
                  </li>
                </ul>
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box mt={4}>
          <Typography
            variant="h6"
            component="h3"
            gutterBottom
            sx={{
              fontSize: {
                xs: "1rem",
                sm: "1.25rem",
                md: "1.5rem",
                lg: "1.75rem",
              },
            }}
          >
            Benefits
          </Typography>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography
                variant="body1"
                component="p"
                gutterBottom
                sx={{
                  fontSize: {
                    xs: "0.875rem",
                    sm: "1rem",
                    md: "1.125rem",
                    lg: "1.25rem",
                  },
                }}
              >
                <ul>
                  <li>
                    <strong>Efficiency:</strong> Automates the tedious process
                    of manually reviewing resumes and matching them with job
                    descriptions, saving time and reducing human error.
                  </li>
                  <li>
                    <strong>Insightful Matching:</strong> Uses AI to provide
                    accurate match percentages, helping recruiters quickly
                    identify the best candidates for a job.
                  </li>
                  <li>
                    <strong>Seamless Communication:</strong> Integrated email
                    functionalities ensure smooth communication between
                    recruiters and candidates.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <img
                src="https://img.freepik.com/free-photo/group-diverse-people-having-business-meeting_53876-25057.jpg"
                alt="Benefits Image"
                style={{ width: "100%", height: "auto" }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box mt={4}>
          <Typography
            variant="h6"
            component="h3"
            gutterBottom
            sx={{
              fontSize: {
                xs: "1rem",
                sm: "1.25rem",
                md: "1.5rem",
                lg: "1.75rem",
              },
            }}
          >
            Technology Stack
          </Typography>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <img
                src="https://img.freepik.com/free-photo/side-view-attractive-hispanic-software-developer-programming-using-computer-while-working-from-home_662251-958.jpg?uid=R157426818&ga=GA1.1.425741652.1718973890&semt=ais_hybrid"
                alt="Technology Stack Image"
                style={{ width: "100%", height: "auto" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant="body1"
                component="p"
                gutterBottom
                sx={{
                  fontSize: {
                    xs: "0.875rem",
                    sm: "1rem",
                    md: "1.125rem",
                    lg: "1.25rem",
                  },
                }}
              >
                <ul>
                  <li>
                    <strong>Backend</strong>: Node.js, Express.js, MongoDB
                  </li>
                  <li>
                    <strong>Frontend</strong>: React.js
                  </li>
                  <li>
                    <strong>AI Integration</strong>: OpenAI for resume parsing
                    and job matching
                  </li>
                </ul>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* <Contact /> */}
    </Box>
  );
};

export default OptimaAI;
