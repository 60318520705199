import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  Link,
  CardActions,
  styled,
  keyframes,
  CardContent,
  CardMedia,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

import AIJob from "./../../Assets/Images/AIJob.png";
import Lambda from "./../../Assets/Images/lambda.jpg";
import cluster from "./../../Assets/Images/cluster.jpg";
import blog1 from "./../../Assets/Images/blog1.jpeg";
import reservedcloud from "./../../Assets/Images/reservedcloud.jpg";
import Soloution from "./../../Assets/Images/Soloution.jpg";
import Whitepaper from "./../../Assets/Images/Whitepaper.svg";
import Mission from "./../../Assets/Images/Mission.jpg";
import Vission from "./../../Assets/Images/Vission.jpg";
import Backgroundvidio from "./../../Assets/Videos/Backgroundvidio.mp4";
import {
  ButtonPrimary,
  ButtonSecondary,
  DividerWithText,
} from "../../Styles/Styles";

import CheckCircleIcon from "@mui/icons-material/AdsClick";
import VisibilityIcon from "@mui/icons-material/Visibility";

import firstamericanbank from "../../Assets/Images/ClientLogos/firstamericanbank.webp";
import Dawn from "../../Assets/Images/ClientLogos/Dawn.png";
import pngegg from "./../../Assets/Images/ClientLogos/pngegg.png";
import NextTrip from "./../../Assets/Images/ClientLogos/NextTrip.jpeg";
import numerix from "./../../Assets/Images/ClientLogos/numerix.png";
import etrmservices from "./../../Assets/Images/ClientLogos/etrmservices.png";
import Falconx from "./../../Assets/Images/ClientLogos/Falconx.jpg";
import Firstsource from "../../Assets/Images/ClientLogos/Firstsource.png";
import CES from "../../Assets/Images/ClientLogos/CES.png";
import BTT from "../../Assets/Images/ClientLogos/BTT.png";

const GradientText = styled("span")(({ theme }) => ({
  background: "var(--gradient2)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  backgroundClip: "text",
  color: "transparent",
}));

const Logo = styled("img")(({ theme }) => ({
  maxWidth: 100,
  margin: theme.spacing(1),
}));

const StyledSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 2),
  scrollMarginTop: "100px", // Adjust this value based on your fixed header height
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(8, 4),
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(4, 2),
  },
}));

const CardStyled = styled(Card)(({ theme }) => ({
  display: "flex",

  flexDirection: "column",
  justifyContent: "space-between",
  padding: theme.spacing(3),
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "scale(1.05)",
  },
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(2),
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1.5),
  },
  [theme.breakpoints.down("xs")]: {
    padding: theme.spacing(1),
  },

  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
}));

const Section = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 2),
  margin: theme.spacing(4, 0),
  backgroundColor: theme.palette.background.paper,
}));

const AIServicesData = [
  {
    title: "APPLIED AI ENGINEERING",
    content: [
      {
        text: "Development ",
        path: "/development",
      },
      { text: "Research", path: "/appliedAIresearch" },
    ],
    icon: `https://media2.giphy.com/media/3o85xp0m4JFY1sLsJ2/200.webp?cid=ecf05e47bk0uw8bty4vfrx7ikt73r1pg5tluc16slpk8bri4&ep=v1_gifs_related&rid=200.webp&ct=g`,
    // backgroundColor: "var(--AI-engineering-card-color)",
    boxShadow:
      "1px 1px 2px var(--AI-engineering-card-color), 0 0 25px var(--AI-engineering-card-color), 0 0 5px var(--AI-engineering-card-color)",
    path: "/ai-engineering",
  },
  {
    title: "APPLIED DATA SCIENCE",
    content: [
      {
        text: " Data Analytics",
        path: "/dataanalytics",
      },
      {
        text: "Data Insights",
        path: "/dataInsights",
      },
    ],
    icon: `https://media1.giphy.com/media/3owyoVlNxHZO0cyHUk/200w.webp?cid=ecf05e47ew40k5k1pqis62z4wq8zu3s9n0myzeauc3cif936&ep=v1_gifs_related&rid=200w.webp&ct=g`,
    // backgroundColor: "var(--Data-science-card-color)",
    boxShadow:
      "1px 1px 2px var(--Data-science-card-color), 0 0 25px var(--Data-science-card-color), 0 0 5px var(--Data-science-card-color)",
    path: "/data-science",
  },
  {
    title: "AI QUALITY",
    content: [
      { text: "Model Validation", path: "/modelValidation" },

      {
        text: "Model Reproducibility",
        path: "/modelReproducibility",
      },
    ],
    icon: "https://media4.giphy.com/media/l44QeWUbKp7gNg4Wk/200w.webp?cid=ecf05e47rwihhhboy3dpoqwgqb1t46r5jmbwwqq1h5iisrvd&ep=v1_gifs_related&rid=200w.webp&ct=g",
    // backgroundColor: "var(--AI-Quality-card)",
    boxShadow:
      "1px 1px 2px var(--AI-Quality-card), 0 0 25px var(--AI-Quality-card), 0 0 5px var(--AI-Quality-card)",
    path: "/ai-quality",
  },
  {
    title: "AI ASSURANCE",
    content: [
      { text: "Model Security", path: "/modalsecurity" },
      { text: "Model Governance", path: "/modelgovernance" },
    ],
    icon: "https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExdjBpdjBjcXVvNWNldjAwaTFrY2hrY2didm01M3pld3NmYTN5Y3UzbCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/ToMjGplMhvFmZ6GdWCI/200w.webp",
    // backgroundColor: "var(--AI-assurence)",
    boxShadow:
      "1px 1px 2px var(--AI-assurence), 0 0 25px var(--AI-assurence), 0 0 5px var(--AI-assurence)",
    path: "/ai-assurance",
  },
];

const AIserviceStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  padding: theme.spacing(2),
  textAlign: "center",
}));

const AISection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 2),
  margin: theme.spacing(4, 0),
  backgroundColor: theme.palette.background.paper,
}));

const TextContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2),
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(2),
  },
}));

// const ScheduleButton = styled(Button)(({ theme }) => ({
//   backgroundColor: "#007F5F",
//   color: "var(--white)",
//   marginTop: theme.spacing(2),
//   "&:hover": {
//     backgroundColor: "#00664F",
//   },
// }));

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  color: "var(--white)",
  padding: theme.spacing(4),
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    textAlign: "center",
  },
}));

const move = keyframes`
  0% { transform: translate(0, 0) rotate(0deg); }
   25% { transform: translate(0px, -90px) rotate(25deg);}
  50% { transform: translate(0px, 90px) rotate(90deg); }
   75% { transform: translate(20px, -20px) rotate(125deg);}
  100% { transform: translate(0, 0) rotate(180deg); }
`;
const rotate = keyframes`
  0% { transform: translate(0, 0) rotate(0deg); }
   25% { transform: translate(0px, -20px) rotate(25deg);}
  50% { transform: translate(0px, 90px) rotate(90deg); }
   75% { transform: translate(-20px, 20px) rotate(125deg);}
  100% { transform: translate(0, 0) rotate(-180deg); }
`;

const ImageSection = styled(Box)(({ theme }) => ({
  textAlign: "center",
  backgroundSize: "cover",
  backgroundPosition: "center",
  color: "var(--white)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  overflow: "hidden",
  minHeight: "50vh",
  [theme.breakpoints.down("sm")]: {
    minHeight: "60vh",
  },
  [theme.breakpoints.down("md")]: {
    minHeight: "50vh",
  },
  [theme.breakpoints.up("lg")]: {
    minHeight: "50vh",
  },
  [theme.breakpoints.up("xl")]: {
    minHeight: "50vh",
  },
}));

const ReviewsBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(2),
  bottom: theme.spacing(2),
  backgroundColor: "var(--white)",
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}));

const Block = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "40px",
  height: "40px",
  backgroundColor: "var(--black)",
  animation: `${move} 3s  ease-in-out infinite`,
}));

const Block2 = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "40px",
  height: "40px",
  backgroundColor: "transparent",
  background: `url(${"https://media4.giphy.com/media/wrcy8dLshNIIhmZzL6/giphy.webp?cid=ecf05e47572ma8dts87hr96gwjvf5atmgp7gqh77epvpzcw6&ep=v1_gifs_related&rid=giphy.webp&ct=g"})`,
  animation: `${rotate}   ease-in-out infinite`,
}));

const Block3 = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "40px",
  height: "40px",
  backgroundColor: "transparent",
  background: `url(${"https://media4.giphy.com/media/wrcy8dLshNIIhmZzL6/giphy.webp?cid=ecf05e47572ma8dts87hr96gwjvf5atmgp7gqh77epvpzcw6&ep=v1_gifs_related&rid=giphy.webp&ct=g"})`,
  animation: `${rotate} 5s ease-in-out infinite`,
}));

const BlockRightTop = styled(Block2)(({ theme }) => ({
  top: theme.spacing(2),
  right: theme.spacing(2),
}));

const BlockRightBottom = styled(Block3)(({ theme }) => ({
  bottom: theme.spacing(0),
  right: theme.spacing(2),
}));

const BlockLeftTop = styled(Block)(({ theme }) => ({
  position: "absolute",
  top: "0%",
  left: "0%",
  width: "40px",
  height: "40px",
  backgroundColor: "transparent",
  background: `url(${"https://media4.giphy.com/media/wrcy8dLshNIIhmZzL6/giphy.webp?cid=ecf05e47572ma8dts87hr96gwjvf5atmgp7gqh77epvpzcw6&ep=v1_gifs_related&rid=giphy.webp&ct=g"})`,
  animation: `${move} 5s ease-in-out infinite`,
  transition: "width 0.3s",
  "&:hover": {
    width: "60px",
  },
}));

const cardsData = [
  {
    title: "AI 360",
    description:
      "AI 360 is a comprehensive AI platform offering end-to-end AI solutions.",
    link: "/ai360",
    backgroundColor: "#FFEBEE", // Light red
    boxShadow:
      "1px 1px 2px var(--Data-science-card-color), 0 0 25px var(--Data-science-card-color), 0 0 5px var(--Data-science-card-color)",
  },
  {
    title: "Cyber Security",
    description:
      "Cyber Security solutions that protect your business from digital threats.",
    link: "/cyber-security",
    backgroundColor: "#E3F2FD", // Light blue
    boxShadow:
      "1px 1px 2px var(--AI-engineering-card-color), 0 0 25px var(--AI-engineering-card-color), 0 0 5px var(--AI-engineering-card-color)",
    path: "/ai-engineering",
  },
  {
    title: "Expert Guidance",
    description:
      "Get expert guidance for implementing AI and cybersecurity solutions.",
    link: "/expertguidance",
    backgroundColor: "#E8F5E9", // Light green
    boxShadow:
      "1px 1px 2px var(--AI-Quality-card), 0 0 25px var(--AI-Quality-card), 0 0 5px var(--AI-Quality-card)",
  },
  {
    title: "Compute ",
    description:
      "Data center will boost our AI capabilities, ensuring we stay at the cutting edge of technology.",
    link: "/compute-data-center",
    backgroundColor: "#e0fcf6", // Light orange
    boxShadow:
      "1px 1px 2px var(--AI-assurence), 0 0 25px var(--AI-assurence), 0 0 5px var(--AI-assurence)",
  },
];

const products = [
  {
    title: "Radius",
    description:
      "Radius is a cybersecurity solution combining SIEM, XDR, and AI to enhance threat detection and response capabilities.",
    link: "/radius",
    boxShadow:
      "1px 1px 2px var(--Data-science-card-color), 0 0 25px var(--Data-science-card-color), 0 0 5px var(--Data-science-card-color)",
  },

  {
    title: "Optima AI",
    description:
      "Optima AI streamlines recruitment with a platform for job descriptions, resume parsing, and job matching features.",
    link: "/optimaAI",
    boxShadow:
      "1px 1px 2px var(--AI-Quality-card), 0 0 25px var(--AI-Quality-card), 0 0 5px var(--AI-Quality-card)",
  },
  {
    title: "Optima GRC",
    description:
      " Optima GRC Program plans governance, manages risks, monitors opportunities, and improves compliance to meet.",
    link: "/optimaGRC",
    boxShadow:
      "1px 1px 2px var(--AI-assurence), 0 0 25px var(--AI-assurence), 0 0 5px var(--AI-assurence)",
  },
  {
    title: "Test IO",
    description:
      "TestIO by Techoptima automates software testing, generating test cases and managing bug reporting efficiently.",
    link: "/testIO",
    boxShadow:
      "1px 1px 2px var(--AI-engineering-card-color), 0 0 25px var(--AI-engineering-card-color), 0 0 5px var(--AI-engineering-card-color)",
  },
];

const logos = [
  {
    src: NextTrip,
    alt: "NextTrip",
  },
  {
    src: Falconx,
    alt: "FalconX",
  },
  {
    src: Firstsource,
    alt: "Firstsource",
  },
  {
    src: etrmservices,
    alt: "ETRM Services",
  },
  {
    src: numerix,
    alt: "Numerix",
  },
  {
    src: CES,
    alt: "CES",
  },
  {
    src: BTT,
    alt: "BTT",
  },
  {
    src: Dawn,
    alt: "Dawn",
  },
  {
    src: firstamericanbank,
    alt: "firstAmerica",
  },
  {
    src: pngegg,
    alt: "pngegg",
  },
];

const blogData = [
  {
    title: "Understanding AI Technology",
    content: "A comprehensive guide to the latest in AI and machine learning.",
    imageUrl: blog1,
  },
  {
    title: "The Future of AI in Business",
    content:
      "How AI is transforming various industries and what the future holds.",
    imageUrl: AIJob,
  },
];

const BlogCard = styled(Card)(({ theme }) => ({
  minWidth: "100%",
  boxShadow: theme.shadows[3],
}));

const BlogContent = styled(CardContent)(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const BlogSection = styled(Box)(({ theme }) => ({
  display: "flex",
  overflow: "hidden",
  paddingBottom: theme.spacing(4),
}));

const BlogWrapper = styled(Box)(({ theme, slideIndex }) => ({
  display: "flex",
  transform: `translateX(-${slideIndex * 100}%)`,
  transition: "transform 0.5s ease-in-out",
  width: "100%",
}));

const HoverContent = styled(CardContent)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  opacity: 0,
  transition: "opacity 0.3s ease",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(2), // Reduced padding to prevent overflow
  boxSizing: "border-box", // Ensure padding is included in width/height
  overflow: "hidden", // Prevent content from overflowing
  textAlign: "center",
}));

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const isSmallScreen = useMediaQuery("(max-width:370px)");
  const is1024px = useMediaQuery("(max-width:1024px)");

  const titleFontSize = isSmallScreen
    ? "1.2rem"
    : isMobile
    ? "1.5rem"
    : isTablet
    ? "2rem"
    : "2.5rem";
  const textFontSize = isSmallScreen
    ? "0.75rem"
    : isMobile
    ? "0.875rem"
    : isTablet
    ? "1rem"
    : "1.125rem";
  const iconSize = isSmallScreen ? 30 : isMobile ? 40 : isTablet ? 50 : 60;

  const [slideIndex, setSlideIndex] = useState(0);
  const [visibleIndexes, setVisibleIndexes] = useState(
    Array(AIServicesData.length).fill(false)
  );
  const navigate = useNavigate();
  const location = useLocation();
  const handleLaunchGPUInstance = () => {
    navigate("/create-account");
  };

  const handleBlogClick = (index) => {
    navigate(`/blog/${index}`);
  };

  const handleToggleVisibility = (index) => {
    setVisibleIndexes((prev) =>
      prev.map((visible, idx) => (idx === index ? !visible : visible))
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setSlideIndex((prevIndex) => (prevIndex + 1) % blogData.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const BlogSection = ({ children }) => (
    <Box sx={{ mt: 4, mb: 4 }}>{children}</Box>
  );

  const [bgPosition, setBgPosition] = useState(null);
  const [isMoving, setIsMoving] = useState(false);
  const moveTimeout = useRef(null);

  const handleMouseMove = (e) => {
    if (!isMoving) {
      setIsMoving(true); // Only set isMoving to true if it's not already true
    }

    const { clientX, clientY, currentTarget } = e;
    const { offsetWidth, offsetHeight } = currentTarget;

    // Calculate the position of the cursor as a percentage of the box size
    const xPos = (clientX / offsetWidth) * 100;
    const yPos = (clientY / offsetHeight) * 100;

    setBgPosition({ x: xPos, y: yPos });

    // Clear any existing timeout
    clearTimeout(moveTimeout.current);

    // Set a timeout to remove the background after a short delay
    moveTimeout.current = setTimeout(() => {
      setIsMoving(false);
    }, 100); // 500ms delay
  };

  useEffect(() => {
    // Clear the timeout when the component unmounts
    return () => clearTimeout(moveTimeout.current);
  }, []);

  return (
    <Box style={{ marginTop: "100px", padding: "0 10px" }}>
      <Box
        onMouseMove={handleMouseMove}
        onMouseLeave={() => setBgPosition(null)}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          mt: 4,
          textAlign: "center",
          py: 8,
          px: 4,
          position: "relative",
          overflow: "hidden", // Ensure the effect doesn't overflow the container
          // background:  w
          //   isMoving && bgPosition
          //     ? `radial-gradient(circle at ${bgPosition.x}% ${bgPosition.y}%, #FF2600, white 20%, transparent 60%)`
          //     : "transparent", // Default to transparent when not moving
          // transition: "background 1s ease",
          // animation: isMoving ? "liquidFlow 2s infinite" : "none",
        }}
      >
        <Box sx={{ flex: 2, textAlign: "center" }}>
          <Typography
            variant="h2"
            style={{
              background:
                "url('https://media4.giphy.com/media/wrcy8dLshNIIhmZzL6/giphy.webp?cid=ecf05e47572ma8dts87hr96gwjvf5atmgp7gqh77epvpzcw6&ep=v1_gifs_related&rid=giphy.webp&ct=g')",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text",
              color: "transparent",
            }}
            gutterBottom
            sx={{
              fontSize: {
                xs: "2.12rem",
                sm: "4rem",
                md: "4rem",
                lg: "5rem",
                xl: "6rem",
              },
            }}
          >
            Your Data. Your AI.
            <br /> Your Compute.
            <br /> Your Future.
          </Typography>

          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontSize: {
                xs: "0.875rem",
                sm: "1rem",
                md: "1.25rem",
                lg: "1.5rem",
              },
            }}
          >
            Own them on the AI Cloud Compute Platform
          </Typography>
          <Box
            sx={{
              mt: 4,
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: 2,
              justifyContent: "center", // Center buttons horizontally
            }}
          >
            <ButtonPrimary
              variant="contained"
              sx={{
                width: { xs: "100%", sm: "auto" },
                height: { xs: 40, sm: "auto" },
                mb: { xs: 2, sm: 0 },
              }}
              onClick={() => navigate("/expertguidance")}
            >
              Expert Guidance
            </ButtonPrimary>
            <ButtonSecondary
              variant="outlined"
              sx={{
                width: { xs: "100%", sm: "auto" },
                height: { xs: 40, sm: "auto" },
              }}
              onClick={() => navigate("/leranmoreforAI")}
            >
              Learn more
            </ButtonSecondary>
          </Box>
        </Box>
      </Box>
      {/* ------------------------------------------------------ */}
      <DividerWithText></DividerWithText>
      <Box
        sx={{
          mt: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography
          variant="h2"
          gutterBottom
          sx={{
            fontSize: isSmallScreen ? "1.5rem" : isTablet ? "2rem" : "2.5rem",
            textAlign: "center",
            marginBottom: theme.spacing(1),
          }}
        >
          Our Clients
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
            width: "80%",

            overflow: "hidden",
            position: "relative",
            padding: "45px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
              width: "100%", // Container width
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
                animation: "scroll 20s linear infinite",
                whiteSpace: "nowrap",
                "@keyframes scroll": {
                  "0%": { transform: "translateX(0)" },
                  "100%": { transform: "translateX(-50%)" }, // Scrolls half of the duplicated content width
                },
                "&:hover": {
                  animationPlayState: "paused", // Pauses the scroll on hover
                },
              }}
            >
              {[...Array(2)].map((_, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    gap: 2,
                  }}
                >
                  {logos.map((logo, i) => (
                    <img
                      key={i}
                      src={logo.src}
                      alt={logo.alt}
                      style={{
                        height: 60,
                        width: "200px",
                        padding: "0 40px",
                      }}
                    />
                  ))}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>

      <DividerWithText></DividerWithText>
      <Box
        sx={{
          position: "relative",
          padding: isSmallScreen
            ? theme.spacing(1)
            : isTablet
            ? theme.spacing(4)
            : theme.spacing(4),
          minHeight: "50vh", // Ensure the box covers the full viewport height
          overflow: "hidden", // Hide overflow
        }}
      >
        <video
          autoPlay
          loop
          muted
          playsInline
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "50%", // Covering 3/4th of the section
            objectFit: "cover",
            zIndex: -1, // Ensure the background is behind the content
          }}
        >
          <source src={Backgroundvidio} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontSize: isSmallScreen ? "1.5rem" : isTablet ? "2rem" : "2.5rem",
            textAlign: "center",
            marginBottom: theme.spacing(1),
            paddingTop: "70px",
            color: "var(--white)",
          }}
        >
          Our Services
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{
            paddingBottom: theme.spacing(2),
            paddingTop: "55px",
          }}
        >
          {cardsData.map((product, index) => (
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              key={index}
              sx={{
                padding: {
                  xs: "80px 0px 40px 0px",
                  lg: "0px",
                },
                textAlign: "center",
              }}
            >
              <CardStyled
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "90%",
                  maxHeight: "300px", // Consistent height for all cards
                  padding: theme.spacing(3),

                  borderRadius: "5px",
                  boxShadow: 3, // Box shadow for card
                }}
              >
                <CardContent sx={{ flexGrow: 1, textAlign: "start" }}>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                      background: "var(--text-gradient)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      backgroundClip: "text",
                      fontSize: isSmallScreen
                        ? "1.25rem"
                        : isMobile
                        ? "1.25rem"
                        : isTablet
                        ? "2.5rem"
                        : "22px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {product.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    paragraph
                    sx={{
                      paddingTop: "15px",
                      fontSize: isSmallScreen
                        ? "1rem"
                        : isTablet
                        ? "1rem"
                        : "1rem",
                    }}
                  >
                    {product.description}
                  </Typography>
                </CardContent>
                <CardActions
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    marginTop: "auto",
                    paddingTop: theme.spacing(2),
                  }}
                >
                  <ButtonPrimary
                    variant="contained"
                    sx={{
                      textTransform: "none",
                    }}
                    onClick={() => navigate(product.link)}
                  >
                    Know more
                  </ButtonPrimary>
                </CardActions>
              </CardStyled>
            </Grid>
          ))}
        </Grid>
      </Box>
      <DividerWithText></DividerWithText>
      <Grid container spacing={2} justifyContent="center" alignItems="stretch">
        {/* Mission Card */}

        <Grid item xs={12} sm={6} md={6}>
          <Card
            style={{
              minHeight: "200px", // Ensure this matches the Mission card for consistency
              display: "flex",
              flexDirection: "column",
              backgroundImage:
                "url(https://img.freepik.com/free-photo/front-view-businessman-with-wooden-building-blocks_23-2148780640.jpg?uid=P156693472&ga=GA1.1.425741652.1718973890&semt=ais_hybrid)",
              backgroundSize: "cover",
              position: "relative",
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
              }}
            ></div>
            <CardContent
              style={{
                position: "relative",
                zIndex: 1,
                flexGrow: 1,
                color: "white",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Grid container direction="column" alignItems="center">
                <Grid item style={{ display: "flex", alignItems: "center" }}>
                  {/* <VisibilityIcon
                    style={{
                      fontSize: iconSize,
                      color: "var(--white)",
                      marginRight: "8px",
                    }}
                  /> */}
                  <Typography
                    variant="h4"
                    style={{
                      fontSize: titleFontSize,
                      textAlign: "left",
                      color: "white",
                    }}
                  >
                    Vision
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    style={{
                      fontSize: textFontSize,
                      textAlign: "center",
                      color: "var(--white)",
                      fontStyle: "italic",
                    }}
                  >
                    Be the preferred partner uniting human ingenuity and AI,
                    leveraging technology and expertise to deliver best-in-class
                    solutions that enhance client success.
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Card
            style={{
              minHeight: "200px", // Increase the minimum height as desired
              display: "flex",
              flexDirection: "column",
              backgroundImage:
                "url(https://img.freepik.com/free-photo/business-hands-joined-together-teamwork_53876-14935.jpg?uid=P156693472&ga=GA1.1.425741652.1718973890&semt=ais_hybrid)",
              backgroundSize: "cover",
              position: "relative",
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
              }}
            ></div>
            <CardContent
              style={{
                position: "relative",
                zIndex: 1,
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Grid container direction="column" alignItems="center">
                <Grid item style={{ display: "flex", alignItems: "center" }}>
                  {/* <CheckCircleIcon
                    style={{ fontSize: iconSize }}
                    sx={{ color: "var(--white)", marginRight: "8px" }}
                  /> */}
                  <Typography
                    variant="h4"
                    style={{
                      fontSize: titleFontSize,
                      textAlign: "left",
                      color: "var(--white)",
                    }}
                  >
                    Mission
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    style={{
                      fontSize: textFontSize,
                      textAlign: "center",
                      color: "var(--white)",
                      fontStyle: "italic",
                    }}
                  >
                    Empowering clients business with AI-driven insights,
                    computational technology excellence, accelerated solutions,
                    and proven delivery.
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Box
        sx={{
          position: "relative",
          padding: isSmallScreen
            ? theme.spacing(1)
            : isTablet
            ? theme.spacing(4)
            : theme.spacing(4),
          minHeight: "50vh", // Ensure the box covers the full viewport height
          overflow: "hidden", // Hide overflow
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontSize: isSmallScreen ? "1.5rem" : isTablet ? "2rem" : "2.5rem",
            textAlign: "center",
            marginBottom: theme.spacing(1),
            paddingTop: "70px",
          }}
        >
          Our Products
        </Typography>

        <Grid
          container
          spacing={2}
          sx={{
            paddingBottom: theme.spacing(2),
            paddingTop: "55px",
          }}
        >
          {products.map((product, index) => (
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              key={index}
              sx={{
                padding: {
                  xs: "80px 0px 40px 0px",
                  lg: "0px",
                },
                textAlign: "center",
              }}
            >
              <CardStyled
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "90%",
                  maxHeight: "300px", // Consistent height for all cards
                  padding: theme.spacing(3),
                  borderRadius: "5px",
                  boxShadow: 3, // Box shadow for card
                }}
              >
                <CardContent sx={{ flexGrow: 1, textAlign: "start" }}>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                      background: "var(--text-gradient)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      backgroundClip: "text",
                      fontSize: isSmallScreen
                        ? "1.25rem"
                        : isMobile
                        ? "1.25rem"
                        : isTablet
                        ? "2.5rem"
                        : "22px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {product.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    paragraph
                    sx={{
                      paddingTop: "15px",
                      fontSize: isSmallScreen
                        ? "1rem"
                        : isTablet
                        ? "1rem"
                        : "1rem",
                    }}
                  >
                    {product.description}
                  </Typography>
                </CardContent>
                <CardActions
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    marginTop: "auto",
                    paddingTop: theme.spacing(2),
                  }}
                >
                  <ButtonPrimary
                    variant="contained"
                    sx={{
                      textTransform: "none",
                    }}
                    onClick={() => navigate(product.link)}
                  >
                    Know more
                  </ButtonPrimary>
                </CardActions>
              </CardStyled>
            </Grid>
          ))}
        </Grid>

        <video
          autoPlay
          loop
          muted
          playsInline
          style={{
            position: "absolute",
            bottom: 0, // Positioning video at the bottom
            left: 0,
            width: "100%",
            height: "50%", // Adjust height as needed to match the desired look
            objectFit: "cover",
            zIndex: -1, // Ensure the background is behind the content
          }}
        >
          <source src={Backgroundvidio} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Box>

      <DividerWithText></DividerWithText>
      <Box>
        <ImageSection>
          <Typography
            variant="h1"
            component="h1"
            style={{
              background:
                "url('https://media4.giphy.com/media/wrcy8dLshNIIhmZzL6/giphy.webp?cid=ecf05e47572ma8dts87hr96gwjvf5atmgp7gqh77epvpzcw6&ep=v1_gifs_related&rid=giphy.webp&ct=g')",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text",
              color: "transparent",
              fontSize: isSmallScreen
                ? "1.75rem"
                : isMobile
                ? "2rem"
                : isTablet
                ? "3rem"
                : "5rem",
            }}
          >
            Your AI and Machine Learning Partner
          </Typography>
          <Typography
            variant="h5"
            component="h5"
            sx={{
              fontSize: isSmallScreen
                ? "0.875rem"
                : isMobile
                ? "1rem"
                : isTablet
                ? "1.25rem"
                : "1.5rem",
            }}
          >
            Don’t just compete. Dominate with AI.
          </Typography>
          <ButtonPrimary
            variant="contained"
            sx={{ mt: 4, width: isSmallScreen ? "100%" : "auto" }}
            onClick={() => navigate("/Contact", { state: { from: "home" } })}
          >
            TELL US ABOUT YOUR BUSINESS CHALLENGE
          </ButtonPrimary>
          <BlockRightTop className="animated-block" />
          <BlockRightBottom className="animated-block" />
          <BlockLeftTop className="animated-block" />
        </ImageSection>
      </Box>
      <DividerWithText></DividerWithText>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "column",
            lg: "row",
          },
          alignItems: "center",
          justifyContent: "space-between",
          mt: 1,
          textAlign: { xs: "center", md: "left" },
          py: 4,
          px: 4,
        }}
      >
        <Box
          sx={{
            flex: 1,
            order: { xs: 1, md: 0 }, // Order blogs at the top on mobile
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontSize: {
                xs: "1.25rem",
                sm: "1.5rem",
                md: "2.5rem",
              },
              textAlign: "center",
              marginBottom: theme.spacing(1),
            }}
          >
            Latest Blogs
          </Typography>
          <BlogSection>
            <Grid container spacing={2}>
              {blogData.slice(0, 2).map((blog, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <BlogCard sx={{ height: "100%" }}>
                    <CardMedia
                      component="img"
                      image={blog.imageUrl}
                      sx={{ height: "340px" }}
                      // title={blog.title}
                    />
                    <BlogContent>
                      <Typography variant="h6" gutterBottom>
                        {blog.title}
                      </Typography>
                      <Typography variant="body2">{blog.content}</Typography>
                    </BlogContent>
                  </BlogCard>
                </Grid>
              ))}
            </Grid>
          </BlogSection>
        </Box>

        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: { xs: 0, sm: 0, md: 0, lg: 0 },
            order: { xs: 2, md: 2 }, // Order image at the bottom on mobile
          }}
        >
          <img
            src={Whitepaper}
            alt="Voltron Data"
            style={{
              borderRadius: "55px 0px 55px 0px",
              maxWidth: "100%",
              // width: isSmallScreen ? "100%" : "90%",
              padding: isSmallScreen
                ? "0"
                : isMobile
                ? "0"
                : isTablet
                ? "25px"
                : is1024px
                ? "0"
                : "35px 25px 0px 45px",
              width: "100%",
              height: isSmallScreen
                ? "100%"
                : isMobile
                ? "100%"
                : isTablet
                ? "90%"
                : "80%",
              margin: isSmallScreen ? "0 auto" : "0",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
