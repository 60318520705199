import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import {
  AppBar,
  Toolbar,
  Typography,
  MenuItem,
  Box,
  IconButton,
  InputBase,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Button as MuiButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import "./Navbar.css";
import Config from "./../../Config/Config";

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState({});
  const [openSubDropdown, setOpenSubDropdown] = useState({});

  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    jobTitle: "",
    companyName: "",
    phone: "",
    country: "",
    jobFunction: "",
  });
  const [popupOpen, setPopupOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const isMobile = useMediaQuery("(max-width:991px)");
  const isSmallMobile = useMediaQuery("(max-width:500px)");
  const isExtraSmallMobile = useMediaQuery("(max-width:300px)");

  const drawerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        setDrawerOpen(false);
      }
    };

    if (drawerOpen && isMobile) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [drawerOpen, isMobile]);

  const handleMouseEnter = (event, menu) => {
    if (!isMobile) {
      setAnchorEl(event.currentTarget);
      setOpenMenu(menu);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setAnchorEl(null);
      setOpenMenu(null);
      setOpenSubMenu(null);
    }
  };

  const handleSubMenuEnter = (event, subMenu) => {
    setOpenSubMenu(subMenu);
  };

  const handleSubMenuLeave = () => {
    setOpenSubMenu(null);
  };

  const handleSearchClick = () => {
    setSearchOpen(!searchOpen);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleDropdownToggle = (menu) => {
    setOpenDropdown((prev) => ({
      ...prev,
      [menu]: !prev[menu],
    }));
  };

  const handleSubDropdownToggle = (menu) => {
    setOpenSubDropdown((prev) => ({
      ...prev,
      [menu]: !prev[menu],
    }));
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const apiEndpoint = `${Config.apiBaseUrl}`;

  const handleDialogSubmit = async () => {
    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pageName: "Become A Client",
          toEmail: formData.email,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Success:", data);
      setPopupOpen(true);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      handleDialogClose();
    }
  };

  const resetForm = () => {
    setFormData({
      firstName: "",
      lastName: "",
      company: "",
      email: "",
      country: "",
      phone: "",
      contactReason: "Select",
      message: "",
    });
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
    console.log("location.state.from", location.pathname);
    navigate(location.pathname);
    resetForm();
  };

  return (
    <>
      <AppBar color="default" elevation={0} className="navbar-container">
        <Toolbar
          className="t-nav"
          sx={{ backgroundColor: "var(--backgroundcolor)" }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
          ></Typography>
          {!isMobile ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <MenuItem
                className="navButtonStyle "
                component={Link}
                to="/optimalabs"
                sx={{
                  color: "var(--button-color) !importent",
                  borderRight: "1px solid #000",
                  padding: "0 15px",
                }}
              >
                Optima Labs
              </MenuItem>
              <MenuItem
                className="navButtonStyle "
                component={Link}
                to="/ows"
                sx={{
                  color: "var(--button-color) !importent",

                  borderRight: "1px solid #000",
                  padding: "0 15px",
                }}
              >
                OWS
              </MenuItem>
              <Box
                onMouseEnter={(e) => handleMouseEnter(e, "about")}
                onMouseLeave={handleMouseLeave}
              >
                <Typography
                  className="navButtonStyle"
                  component={HashLink}
                  to="/about#company"
                  sx={{
                    borderRight: "1px solid #000",
                    padding: "0 15px",
                  }}
                >
                  About
                </Typography>
                {openMenu === "about" && (
                  <Box
                    sx={{
                      position: "absolute",
                      backgroundColor: "var(--white)",
                      zIndex: 1,
                      boxShadow: 3,
                      p: 2,
                    }}
                    onMouseEnter={(e) => handleMouseEnter(e, "about")}
                    onMouseLeave={handleMouseLeave}
                  >
                    <MenuItem
                      component={HashLink}
                      to="/about#company"
                      onClick={handleMouseLeave}
                      className="drp-down-opt"
                      sx={{
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      Company
                    </MenuItem>

                    <MenuItem
                      component={HashLink}
                      to="/about#vision"
                      onClick={handleMouseLeave}
                      className="drp-down-opt"
                      sx={{
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      Vision
                    </MenuItem>
                    <MenuItem
                      component={HashLink}
                      to="/about#mission"
                      onClick={handleMouseLeave}
                      className="drp-down-opt"
                      sx={{
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      Mission
                    </MenuItem>
                    <MenuItem
                      component={HashLink}
                      to="/about#values"
                      onClick={handleMouseLeave}
                      className="drp-down-opt"
                      sx={{
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      Values
                    </MenuItem>
                  </Box>
                )}
              </Box>
              <MenuItem
                className="navButtonStyle"
                component={Link}
                to="/careers"
                sx={{
                  borderRight: "1px solid #000",
                  padding: "0 15px",
                }}
              >
                Careers
              </MenuItem>
              <MenuItem
                className="navButtonStyle"
                component={Link}
                to="/sign-up"
                sx={{
                  padding: "0 15px",
                }}
              >
                Sign Up
              </MenuItem>
            </Box>
          ) : (
            <IconButton
              color="var(--button-color)"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
        <Toolbar className="toolbar-main">
          <Box
            component={Link}
            to="/"
            sx={{
              textDecoration: "none",
              backgroundColor: "var(--button-color)",
              padding: "8px 12px ",
              borderRadius: "15px 0 15px 0",
              boxShadow:
                "0px 0px 8px var(--white), 0 0 0px 1px var(--button-color), 0 0 0px 1px var(--button-color)",
              display: "inline-block",
            }}
          >
            <Typography
              variant="p"
              sx={{
                fontWeight: "900",
                color: "white",
                textDecoration: "none",
              }}
            >
              TechOptima
            </Typography>
          </Box>

          {!isMobile && (
            <>
              <Box
                onMouseEnter={(e) => handleMouseEnter(e, "expertgidence")}
                onMouseLeave={handleMouseLeave}
              >
                <Typography
                  className="navButtonStyle"
                  sx={{
                    borderRight: "1px solid #000",
                    paddingLeft: "55px",
                  }}
                >
                  Expert Guidance
                </Typography>
                {openMenu === "expertgidence" && (
                  <Box
                    sx={{
                      position: "absolute",
                      backgroundColor: "var(--white)",
                      zIndex: 1,
                      boxShadow: 3,
                      p: 2,
                      width: 950,
                    }}
                    onMouseEnter={(e) => handleMouseEnter(e, "expertgidence")}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography
                            variant="h6"
                            className="drp-dwn-head"
                            style={{
                              marginBottom: "10px",
                              marginLeft: 0,
                              textDecoration: "none",
                              "&:hover": {
                                color: "var(--button-color)!importent",
                              },
                            }}
                            component={Link}
                            to="/ai360"
                          >
                            AI 360
                          </Typography>
                          <MenuItem
                            component={Link}
                            to="/ai-engineering"
                            onClick={handleMouseLeave}
                            className="drp-down-opt"
                            style={{ paddingLeft: 0 }}
                          >
                            Applied AI Engineering
                          </MenuItem>
                          <MenuItem
                            component={Link}
                            to="/data-science"
                            onClick={handleMouseLeave}
                            className="drp-down-opt"
                            style={{ paddingLeft: 0 }}
                          >
                            Applied Data Science
                          </MenuItem>

                          <MenuItem
                            component={Link}
                            to="/ai-quality"
                            onClick={handleMouseLeave}
                            className="drp-down-opt"
                            style={{ paddingLeft: 0 }}
                          >
                            AI Quality
                          </MenuItem>
                          <MenuItem
                            component={Link}
                            to="/ai-assurance"
                            onClick={handleMouseLeave}
                            className="drp-down-opt"
                            style={{ paddingLeft: 0 }}
                          >
                            AI Assurance
                          </MenuItem>
                        </div>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography
                            onClick={handleMouseLeave}
                            variant="h6"
                            className="drp-dwn-head"
                            style={{
                              marginTop: "15px",
                              marginLeft: 0,
                              textDecoration: "none",
                              "&:hover": {
                                color: "var(--button-color)!importent",
                              },
                            }}
                            component={Link}
                            to="/cyber-security"
                          >
                            Cyber Security
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        style={{
                          fontSize: "14px",
                          color: "var(--fontcolor)",
                          fontWeight: "500",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography
                            variant="h6"
                            className="drp-dwn-head"
                            style={{ marginBottom: "10px", marginLeft: 0 }}
                          >
                            Cloud
                          </Typography>
                          <MenuItem
                            component={Link}
                            to="/cloud-transformation"
                            onClick={handleMouseLeave}
                            className="drp-down-opt"
                            style={{ paddingLeft: 0 }}
                          >
                            Cloud Transformation
                          </MenuItem>
                          <MenuItem
                            component={Link}
                            to="/cloud-managed-services"
                            onClick={handleMouseLeave}
                            className="drp-down-opt"
                            style={{ paddingLeft: 0 }}
                          >
                            Cloud Managed Services
                          </MenuItem>
                          <MenuItem
                            component={Link}
                            to="/cloud-migrations"
                            onClick={handleMouseLeave}
                            className="drp-down-opt"
                            style={{ paddingLeft: 0 }}
                          >
                            Cloud Migrations
                          </MenuItem>
                          <MenuItem
                            component={Link}
                            to="/cloud-operations"
                            onClick={handleMouseLeave}
                            className="drp-down-opt"
                            style={{ paddingLeft: 0 }}
                          >
                            Cloud Operations
                          </MenuItem>
                          <MenuItem
                            component={Link}
                            to="/cloud-native-apps"
                            onClick={handleMouseLeave}
                            className="drp-down-opt"
                            style={{ paddingLeft: 0 }}
                          >
                            Cloud Native Application Development
                          </MenuItem>
                          <MenuItem
                            component={Link}
                            to="/cloud-devops"
                            onClick={handleMouseLeave}
                            className="drp-down-opt"
                            style={{ paddingLeft: 0 }}
                          >
                            Cloud DevOps
                          </MenuItem>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        style={{
                          fontSize: "14px",
                          color: "var(--fontcolor)",
                          fontWeight: "500",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography
                            variant="h6"
                            className="drp-dwn-head"
                            style={{ marginBottom: "10px", marginLeft: 0 }}
                          >
                            Digital Services
                          </Typography>
                          <MenuItem
                            component={Link}
                            to="/enterprise-development"
                            onClick={handleMouseLeave}
                            className="drp-down-opt"
                            style={{ paddingLeft: 0 }}
                          >
                            Enterprise application Development
                            <br /> (Mobile, Web)
                          </MenuItem>
                          <MenuItem
                            component={Link}
                            to="/enterprise-platform"
                            onClick={handleMouseLeave}
                            className="drp-down-opt"
                            style={{ paddingLeft: 0 }}
                          >
                            Enterprise platform development <br />
                            (Oracle, SAP, Salesforce, ServiceNow)
                          </MenuItem>
                          <MenuItem
                            component={Link}
                            to="/agile-devops"
                            onClick={handleMouseLeave}
                            className="drp-down-opt"
                            style={{ paddingLeft: 0 }}
                          >
                            Agile DevOps
                          </MenuItem>
                          <MenuItem
                            component={Link}
                            to="/intelligent-automation"
                            onClick={handleMouseLeave}
                            className="drp-down-opt"
                            style={{ paddingLeft: 0 }}
                          >
                            Intelligent Automation RPA
                          </MenuItem>
                          <MenuItem
                            component={Link}
                            to="/low-code"
                            onClick={handleMouseLeave}
                            className="drp-down-opt"
                            style={{ paddingLeft: 0 }}
                          >
                            Low Code Application
                          </MenuItem>
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
              <Box
                onMouseEnter={(e) => handleMouseEnter(e, "products")}
                onMouseLeave={handleMouseLeave}
              >
                <Typography
                  className="navButtonStyle"
                  sx={{
                    borderRight: "1px solid #000",
                  }}
                >
                  Products
                </Typography>
                {openMenu === "products" && (
                  <Box
                    sx={{
                      position: "absolute",
                      backgroundColor: "var(--white)",
                      zIndex: 1,
                      boxShadow: 3,
                      p: 2,
                    }}
                    onMouseEnter={(e) => handleMouseEnter(e, "products")}
                    onMouseLeave={handleMouseLeave}
                  >
                    <MenuItem
                      component={Link}
                      to="/radius"
                      onClick={handleMouseLeave}
                      className="drp-down-opt"
                      sx
                    >
                      Radius
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to="/testIO"
                      onClick={handleMouseLeave}
                      className="drp-down-opt"
                    >
                      Test IO
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to="/optimaAI"
                      onClick={handleMouseLeave}
                      className="drp-down-opt"
                    >
                      Optima AI
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to="/optimaGRC"
                      onClick={handleMouseLeave}
                      className="drp-down-opt"
                    >
                      Optima GRC
                    </MenuItem>
                  </Box>
                )}
              </Box>
              <Box
                onMouseEnter={(e) => handleMouseEnter(e, "dataCenter")}
                onMouseLeave={handleMouseLeave}
              >
                <Typography
                  className="navButtonStyle"
                  sx={{
                    borderRight: "1px solid #000",
                  }}
                  component={Link}
                  to="/compute-data-center"
                >
                  Compute
                </Typography>
                {openMenu === "dataCenter" && (
                  <Box
                    sx={{
                      position: "absolute",
                      backgroundColor: "var(--white)",
                      zIndex: 1,
                      boxShadow: 3,
                      p: 2,
                    }}
                    onMouseEnter={(e) => handleMouseEnter(e, "dataCenter")}
                    onMouseLeave={handleMouseLeave}
                  >
                    <MenuItem
                      component={Link}
                      to="/OPTIMA"
                      onClick={handleMouseLeave}
                      className="drp-down-opt"
                    >
                      Optima Systems
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to="/scalarserver"
                      onClick={handleMouseLeave}
                      className="drp-down-opt"
                    >
                      OptimaX
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to="/chintu"
                      onClick={handleMouseLeave}
                      className="drp-down-opt"
                    >
                      Chintu
                    </MenuItem>
                  </Box>
                )}
              </Box>
              <Box
                onMouseEnter={(e) => handleMouseEnter(e, "resources")}
                onMouseLeave={handleMouseLeave}
              >
                <Typography className="navButtonStyle">Resources</Typography>
                {openMenu === "resources" && (
                  <Box
                    sx={{
                      position: "absolute",
                      backgroundColor: "var(--white)",
                      zIndex: 1,
                      boxShadow: 3,
                      p: 2,
                    }}
                    onMouseEnter={(e) => handleMouseEnter(e, "resources")}
                    onMouseLeave={handleMouseLeave}
                  >
                    <MenuItem
                      component={Link}
                      to="/blogs"
                      onClick={handleMouseLeave}
                      className="drp-down-opt"
                    >
                      Blogs
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to="/research"
                      onClick={handleMouseLeave}
                      className="drp-down-opt"
                    >
                      Research
                    </MenuItem>
                    <MenuItem
                      component={Link}
                      to="/white-papers"
                      onClick={handleMouseLeave}
                      className="drp-down-opt"
                    >
                      White Papers
                    </MenuItem>
                  </Box>
                )}
              </Box>
            </>
          )}
          <Box sx={{ flexGrow: 1 }} />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              paddingRight: isMobile ? "20px" : "45px",
            }}
          >
            <MuiButton
              sx={{
                mt: "15px",
                backgroundColor: isMobile
                  ? "transparent"
                  : "var(--button-color)",
                color: isMobile ? "var(--buttontextcolor)" : "var(--white)",
                whiteSpace: "nowrap",
                borderRadius: "0",
                fontSize: isExtraSmallMobile
                  ? "10px"
                  : isSmallMobile
                  ? "12px"
                  : "15px",
                fontWeight: "800",
                lineHeight: "1.2",
                padding: isExtraSmallMobile
                  ? "8px 16px"
                  : isSmallMobile
                  ? "10px 20px"
                  : "15px 32px",
                textDecoration: "none",
                width: isExtraSmallMobile
                  ? "100px"
                  : isSmallMobile
                  ? "120px"
                  : "auto",
                "&:hover": {
                  backgroundColor: isMobile
                    ? "transparent"
                    : "var(--buttonHover)",
                  "& span": {
                    paddingLeft: "10px",
                    transform: "translateX(6px)",
                    transition: "transform 0.1s ease-in-out",
                  },
                },
              }}
              component={Link}
              onClick={handleDialogOpen}
            >
              Become a Client
              <span
                style={{
                  color: isExtraSmallMobile
                    ? "var(--button-color)"
                    : isSmallMobile
                    ? "var(--button-color)"
                    : "var(--white)",
                  fontSize: isExtraSmallMobile
                    ? "14px"
                    : isSmallMobile
                    ? "16px"
                    : "19px",
                  paddingLeft: "10px",
                  fontWeight: "800",
                }}
              >
                &#8599;
              </span>
            </MuiButton>

            <Typography
              variant="body2"
              sx={{
                fontWeight: isMobile ? "600" : "800",
                fontSize: isExtraSmallMobile
                  ? "12px"
                  : isSmallMobile
                  ? "12px"
                  : "12px",
                m: isExtraSmallMobile ? "0px" : isSmallMobile ? "0px" : "1px",
              }}
            >
              or call{" "}
              <a
                href="tel:+917032917578"
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  fontSize: isExtraSmallMobile
                    ? "12px"
                    : isSmallMobile
                    ? "12px"
                    : "12px",
                  fontWeight: isMobile ? "600" : "800",
                }}
              >
                +91 70329 17578
              </a>
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer anchor="left" open={drawerOpen}>
        <Box ref={drawerRef} sx={{ width: 250 }} role="presentation">
          <IconButton
            sx={{
              color: "var(--button-color)",
              marginLeft: "auto",
              marginRight: "10px",
            }}
            onClick={handleDrawerToggle}
          >
            <CloseIcon />
          </IconButton>
          <List>
            <ListItem
              button
              component={Link}
              to="/optimalabs"
              onClick={handleCloseDrawer}
            >
              <ListItemText
                primary="Optima Labs"
                sx={{
                  color: "var(--fontcolor)",
                  "&:hover": {
                    color: "var(--blue)",
                  },
                }}
              />
            </ListItem>

            <ListItem component={Link} to="/ows" onClick={handleCloseDrawer}>
              <ListItemText
                primary="OWS"
                sx={{
                  color: "var(--fontcolor)",
                  "&:hover": {
                    color: "var(--blue)",
                  },
                }}
              />
            </ListItem>
            <ListItem button onClick={() => handleDropdownToggle("about")}>
              <ListItemText primary="About" sx={{ color: "var(--fontcolor" }} />
              {openDropdown["about"] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openDropdown["about"]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  component={HashLink}
                  to="/about#company"
                  onClick={handleCloseDrawer}
                >
                  <ListItemText
                    primary="Company"
                    sx={{
                      fontSize: "14px",
                      pl: 2,
                      color: "var(--fontcolor)",
                      "&:hover": {
                        color: "var(--blue)",
                      },
                    }}
                  />
                </ListItem>
                <ListItem
                  button
                  component={HashLink}
                  to="/about#vision"
                  onClick={handleCloseDrawer}
                >
                  <ListItemText
                    primary="Vision"
                    sx={{
                      fontSize: "14px",
                      pl: 2,
                      color: "var(--fontcolor)",
                      "&:hover": {
                        color: "var(--blue)",
                      },
                    }}
                  />
                </ListItem>
                <ListItem
                  button
                  component={HashLink}
                  to="/about#mission"
                  onClick={handleCloseDrawer}
                >
                  <ListItemText
                    primary="Mission"
                    sx={{
                      fontSize: "14px",
                      pl: 2,
                      color: "var(--fontcolor)",
                      "&:hover": {
                        color: "var(--blue)",
                      },
                    }}
                  />
                </ListItem>
                <ListItem
                  button
                  component={HashLink}
                  to="/about#values"
                  onClick={handleCloseDrawer}
                >
                  <ListItemText
                    primary="Values"
                    sx={{
                      fontSize: "14px",
                      pl: 2,
                      color: "var(--fontcolor)",
                      "&:hover": {
                        color: "var(--blue)",
                      },
                    }}
                  />
                </ListItem>
              </List>
            </Collapse>
            <ListItem
              button
              component={Link}
              to="/careers"
              onClick={handleCloseDrawer}
            >
              <ListItemText
                primary="Careers"
                sx={{
                  color: "var(--fontcolor)",
                  "&:hover": {
                    color: "var(--blue)",
                  },
                }}
              />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/sign-up"
              onClick={handleCloseDrawer}
            >
              <ListItemText
                primary="Sign Up"
                sx={{
                  color: "var(--fontcolor)",
                  "&:hover": {
                    color: "var(--blue)",
                  },
                }}
              />
            </ListItem>
            <ListItem
              button
              onClick={() => handleDropdownToggle("Expert Guidence")}
            >
              <ListItemText
                primary="Expert Guidance"
                sx={{ fontSize: "14px", color: "var(--fontcolor)" }}
              />
              {openDropdown["Expert Guidence"] ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </ListItem>
            <Collapse
              in={openDropdown["Expert Guidence"]}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItem
                  button
                  onClick={() => handleSubDropdownToggle("ai360")}
                  component={Link}
                  to="/ai360"
                >
                  <ListItemText
                    primary="AI 360"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      pl: 2,
                      color: "var(--fontcolor)",
                    }}
                  />
                  {openSubDropdown["ai360"] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>

                <Collapse
                  in={openSubDropdown["ai360"]}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      component={Link}
                      to="/ai-engineering"
                      onClick={handleCloseDrawer}
                    >
                      <ListItemText
                        primary="Applied AI Engineering"
                        sx={{
                          fontSize: "14px",
                          pl: 4,
                          color: "var(--fontcolor)",
                          "&:hover": {
                            color: "var(--blue)",
                          },
                        }}
                      />
                    </ListItem>
                    <ListItem
                      button
                      component={Link}
                      to="/data-science"
                      onClick={handleCloseDrawer}
                    >
                      <ListItemText
                        primary="Applied Data Science"
                        sx={{
                          fontSize: "14px",
                          pl: 4,
                          color: "var(--fontcolor)",
                          "&:hover": {
                            color: "var(--blue)",
                          },
                        }}
                      />
                    </ListItem>
                    <ListItem
                      button
                      component={Link}
                      to="/ai-quality"
                      onClick={handleCloseDrawer}
                    >
                      <ListItemText
                        primary="AI Quality"
                        sx={{
                          fontSize: "14px",
                          pl: 4,
                          color: "var(--optionsColor)",
                          "&:hover": {
                            color: "var(--blue)",
                          },
                        }}
                      />
                    </ListItem>
                    <ListItem
                      button
                      component={Link}
                      to="/ai-assurance"
                      onClick={handleCloseDrawer}
                    >
                      <ListItemText
                        primary="AI Assurance"
                        sx={{
                          fontSize: "14px",
                          pl: 4,
                          color: "var(--fontcolor)",
                          "&:hover": {
                            color: "var(--blue)",
                          },
                        }}
                      />
                    </ListItem>
                  </List>
                </Collapse>

                <ListItem
                  button
                  onClick={() => handleSubDropdownToggle("cloud")}
                >
                  <ListItemText
                    primary="Cloud"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      pl: 2,
                      color: "var(--fontcolor)",
                    }}
                  />
                  {openSubDropdown["cloud"] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  in={openSubDropdown["cloud"]}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      component={Link}
                      to="/cloud-transformation"
                      onClick={handleCloseDrawer}
                    >
                      <ListItemText
                        primary="Cloud Transformation"
                        sx={{
                          fontSize: "14px",
                          pl: 4,
                          color: "var(--fontcolor)",
                          "&:hover": {
                            color: "var(--blue)",
                          },
                        }}
                      />
                    </ListItem>
                    <ListItem
                      button
                      component={Link}
                      to="/cloud-managed-services"
                      onClick={handleCloseDrawer}
                    >
                      <ListItemText
                        primary="Cloud Managed Services"
                        sx={{
                          fontSize: "14px",
                          pl: 4,
                          color: "var(--fontcolor)",
                          "&:hover": {
                            color: "var(--blue)",
                          },
                        }}
                      />
                    </ListItem>
                    <ListItem
                      button
                      component={Link}
                      to="/cloud-migrations"
                      onClick={handleCloseDrawer}
                    >
                      <ListItemText
                        primary="Cloud Migrations"
                        sx={{
                          fontSize: "14px",
                          pl: 4,
                          color: "var(--fontcolor)",
                          "&:hover": {
                            color: "var(--blue)",
                          },
                        }}
                      />
                    </ListItem>
                    <ListItem
                      button
                      component={Link}
                      to="/cloud-operations"
                      onClick={handleCloseDrawer}
                    >
                      <ListItemText
                        primary="Cloud Operations"
                        sx={{
                          fontSize: "14px",
                          pl: 4,
                          color: "var(--fontcolor)",
                          "&:hover": {
                            color: "var(--blue)",
                          },
                        }}
                      />
                    </ListItem>
                    <ListItem
                      button
                      component={Link}
                      to="/cloud-native-apps"
                      onClick={handleCloseDrawer}
                    >
                      <ListItemText
                        primary="Cloud Native Application Development"
                        sx={{
                          fontSize: "14px",
                          pl: 4,
                          color: "var(--fontcolor)",
                          "&:hover": {
                            color: "var(--blue)",
                          },
                        }}
                      />
                    </ListItem>
                    <ListItem
                      button
                      component={Link}
                      to="/cloud-devops"
                      onClick={handleCloseDrawer}
                    >
                      <ListItemText
                        primary="Cloud DevOps"
                        sx={{
                          fontSize: "14px",
                          pl: 4,
                          color: "var(--fontcolor)",
                          "&:hover": {
                            color: "var(--blue)",
                          },
                        }}
                      />
                    </ListItem>
                  </List>
                </Collapse>
                <ListItem
                  button
                  onClick={() => handleSubDropdownToggle("digitalServices")}
                >
                  <ListItemText
                    primary="Digital Services"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      pl: 2,
                      color: "var(--fontcolor)",
                    }}
                  />
                  {openSubDropdown["digitalServices"] ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItem>
                <Collapse
                  in={openSubDropdown["digitalServices"]}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      component={Link}
                      to="/enterprise-development"
                      onClick={handleCloseDrawer}
                    >
                      <ListItemText
                        primary="Enterprise application Development (Mobile web)"
                        sx={{
                          fontSize: "14px",
                          pl: 4,
                          color: "var(--fontcolor)",
                          "&:hover": {
                            color: "var(--blue)",
                          },
                        }}
                      />
                    </ListItem>
                    <ListItem
                      button
                      component={Link}
                      to="/enterprise-platform"
                      onClick={handleCloseDrawer}
                    >
                      <ListItemText
                        primary="Enterprise platform development (Oracle, SAP, Salesforce, ServiceNow)"
                        sx={{
                          fontSize: "14px",
                          pl: 4,
                          color: "var(--fontcolor)",
                          "&:hover": {
                            color: "var(--blue)",
                          },
                        }}
                      />
                    </ListItem>
                    <ListItem
                      button
                      component={Link}
                      to="/agile-devops"
                      onClick={handleCloseDrawer}
                    >
                      <ListItemText
                        primary="Agile DevOps"
                        sx={{
                          fontSize: "14px",
                          pl: 4,
                          color: "var(--fontcolor)",
                          "&:hover": {
                            color: "var(--blue)",
                          },
                        }}
                      />
                    </ListItem>
                    <ListItem
                      button
                      component={Link}
                      to="/intelligent-automation"
                      onClick={handleCloseDrawer}
                    >
                      <ListItemText
                        primary="Intelligent Automation RPA"
                        sx={{
                          fontSize: "14px",
                          pl: 4,
                          color: "var(--fontcolor)",
                          "&:hover": {
                            color: "var(--blue)",
                          },
                        }}
                      />
                    </ListItem>
                    <ListItem
                      button
                      component={Link}
                      to="/low-code"
                      onClick={handleCloseDrawer}
                    >
                      <ListItemText
                        primary="Low Code Application"
                        sx={{
                          fontSize: "14px",
                          pl: 4,
                          color: "var(--fontcolor)",
                          "&:hover": {
                            color: "var(--blue)",
                          },
                        }}
                      />
                    </ListItem>
                  </List>
                </Collapse>
              </List>
              <ListItem
                button
                onClick={handleCloseDrawer}
                component={Link}
                to="/cyber-security"
              >
                <ListItemText
                  primary="Cyber Security"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    pl: 2,
                    color: "var(--fontcolor)",
                  }}
                />
              </ListItem>
            </Collapse>

            <ListItem button onClick={() => handleDropdownToggle("tools")}>
              <ListItemText
                primary="Tools"
                sx={{ color: "var(--fontcolor)" }}
              />
              {openDropdown["tools"] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openDropdown["tools"]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  component={Link}
                  to="/radius"
                  onClick={handleCloseDrawer}
                >
                  <ListItemText
                    primary="Radius"
                    sx={{
                      fontSize: "14px",
                      pl: 2,
                      color: "var(--fontcolor)",
                      "&:hover": {
                        color: "var(--blue)",
                      },
                    }}
                  />
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/testIO"
                  onClick={handleCloseDrawer}
                >
                  <ListItemText
                    primary="Test IO"
                    sx={{
                      fontSize: "14px",
                      pl: 2,
                      color: "var(--fontcolor)",
                      "&:hover": {
                        color: "var(--blue)",
                      },
                    }}
                  />
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/optimaAI"
                  onClick={handleCloseDrawer}
                >
                  <ListItemText
                    primary="Optima AI"
                    sx={{
                      fontSize: "14px",
                      pl: 2,
                      color: "var(--fontcolor)",
                      "&:hover": {
                        color: "var(--blue)",
                      },
                    }}
                  />
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/optimaGRC"
                  onClick={handleCloseDrawer}
                >
                  <ListItemText
                    primary="Optima GRC"
                    sx={{
                      fontSize: "14px",
                      pl: 2,
                      color: "var(--fontcolor)",
                      "&:hover": {
                        color: "var(--blue)",
                      },
                    }}
                  />
                </ListItem>
              </List>
            </Collapse>
            <ListItem
              button
              component={Link}
              to="/compute-data-center"
              onClick={() => handleDropdownToggle("Compute")}
            >
              <ListItemText
                primary="Compute"
                sx={{ color: "var(--fontcolor)" }}
              />
              {openDropdown["Compute"] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openDropdown["Compute"]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  component={Link}
                  to="/OPTIMA"
                  onClick={handleCloseDrawer}
                >
                  <ListItemText
                    primary="OPTIMA Systems"
                    sx={{
                      fontSize: "14px",
                      pl: 2,
                      color: "var(--fontcolor)",
                      "&:hover": {
                        color: "var(--blue)",
                      },
                    }}
                  />
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/scalarserver"
                  onClick={handleCloseDrawer}
                >
                  <ListItemText
                    primary="Optima X"
                    sx={{
                      fontSize: "14px",
                      pl: 2,
                      color: "var(--fontcolor)",
                      "&:hover": {
                        color: "var(--blue)",
                      },
                    }}
                  />
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/chintu"
                  onClick={handleCloseDrawer}
                >
                  <ListItemText
                    primary="Chintu"
                    sx={{
                      fontSize: "14px",
                      pl: 2,
                      color: "var(--fontcolor)",
                      "&:hover": {
                        color: "var(--blue)",
                      },
                    }}
                  />
                </ListItem>
              </List>
            </Collapse>
            <ListItem button onClick={() => handleDropdownToggle("resources")}>
              <ListItemText
                primary="Resources"
                sx={{ color: "var(--fontcolor" }}
              />
              {openDropdown["resources"] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              in={openDropdown["resources"]}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItem
                  button
                  component={Link}
                  to="/blogs"
                  onClick={handleCloseDrawer}
                >
                  <ListItemText
                    primary="Blogs"
                    sx={{
                      fontSize: "14px",
                      pl: 2,
                      color: "var(--fontcolor)",
                      "&:hover": {
                        color: "var(--blue)",
                      },
                    }}
                  />
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/research"
                  onClick={handleCloseDrawer}
                >
                  <ListItemText
                    primary="Research"
                    sx={{
                      fontSize: "14px",
                      pl: 2,
                      color: "var(--fontcolor)",
                      "&:hover": {
                        color: "var(--blue)",
                      },
                    }}
                  />
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/white-papers"
                  onClick={handleCloseDrawer}
                >
                  <ListItemText
                    primary="White Papers"
                    sx={{
                      fontSize: "14px",
                      pl: 2,
                      color: "var(--fontcolor)",
                      "&:hover": {
                        color: "var(--blue)",
                      },
                    }}
                  />
                </ListItem>
              </List>
            </Collapse>
          </List>
        </Box>
      </Drawer>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Become a Client</DialogTitle>
        <DialogContent>
          <Typography variant="body1" paragraph>
            Clients receive 24/7 access to proven management and technology
            research, expert advice, benchmarks, diagnostics and more. Fill out
            the form to connect with a representative and learn more.
          </Typography>
          <TextField
            margin="dense"
            label="Work Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="First Name"
            name="firstName"
            type="text"
            value={formData.firstName}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Last Name"
            name="lastName"
            type="text"
            value={formData.lastName}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Job Title"
            name="jobTitle"
            type="text"
            value={formData.jobTitle}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Company Name"
            name="companyName"
            type="text"
            value={formData.companyName}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Phone"
            name="phone"
            type="tel"
            value={formData.phone}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Country"
            name="country"
            type="text"
            value={formData.country}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Job Function"
            name="jobFunction"
            type="text"
            value={formData.jobFunction}
            onChange={handleInputChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={handleDialogClose} color="primary">
            Cancel
          </MuiButton>
          <MuiButton onClick={handleDialogSubmit} color="primary">
            Submit
          </MuiButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={popupOpen}
        onClose={handlePopupClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Submission Successful</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Your request to "Become A Client" has been submitted successfully!
          </Typography>
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={handlePopupClose} color="primary">
            OK
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Navbar;
